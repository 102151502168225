/* eslint-disable react/jsx-curly-newline */
import React, { useContext, useState } from "react";
import {
  Banner,
  Button,
  Flex,
  Text,
  theme,
  Panel,
} from "@xometry/xometry_loft";
import { useQuery } from "@apollo/client";
import { AuthContext } from "@xometry/auth-context";
import {
  FullOrganizationInvitationFragment,
  GetOrganizationInvitationsByEmailQuery,
  GetOrganizationInvitationsByEmailQueryResult,
  User,
} from "../../schema/generated/schema";
import InvitationModal, { InviteModalData } from "./invitationModal";

type PendingInvitesBannerProps = {
  loggedInUser: User;
};

const PendingInvitesBanner = (props: PendingInvitesBannerProps) => {
  const { loggedInUser } = props;
  const { isStaff } = useContext(AuthContext);

  const { data } = useQuery<GetOrganizationInvitationsByEmailQueryResult>(
    GetOrganizationInvitationsByEmailQuery,
    {
      variables: {
        email: loggedInUser.emailAddress,
      },
    }
  );

  const invites = data?.getOrganizationInvitationsByEmail || [];

  const [showBanner, setShowBanner] = useState(true);
  const [
    invitationModalData,
    setInvitationModalData,
  ] = useState<InviteModalData | null>(null);

  if (!showBanner || invites.length === 0) return null;

  const acceptInvite = (invite: FullOrganizationInvitationFragment) => {
    setInvitationModalData({
      ...invite,
      inviterFirstName: invite?.senderUserData?.firstName || "",
      inviterLastName: invite?.senderUserData?.lastName || "",
      teamName: invite.organization.name,
      openStatus: true,
      url: invite.acceptInviteUrl || "",
      buttonDecision: "Accept",
      userEmail: invite?.userData?.email || "",
      orgId: invite.organization.id,
      isStaffActingOnBehalfOfUser: isStaff(),
      userName: "",
    });
  };
  const openPendingView = () => {
    window.location.href =
      "https://www.stage.xometry.net/user-settings?view=pending";
  };
  if (!invites || invites.length === 0) return null;
  const invite = invites[0];
  const senderName = invite?.senderUserData?.email?.endsWith("@xometry.com")
    ? `You're invited`
    : `${invite?.senderUserData?.firstName} invited you`;
  return (
    <Panel padding={0} border="none" marginBottom={theme.space[6]}>
      <Banner kind={theme.kind.primary}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="column">
            <Text fontWeight={600}>
              {invites.length === 1
                ? `${senderName} to join ${invite?.organization?.name}`
                : "Your team invitations are waiting for you."}
            </Text>
            <Text fontSize="13px">
              {invites.length === 1
                ? "Join your team to collaborate on quotes, get updates, and much more."
                : "View and accept your invitations to start collaborating on quotes, get team order updates, and much more."}
            </Text>
          </Flex>
          <Flex gap={theme.space[3]} height="34px">
            <Button onClick={() => setShowBanner(false)}>Dismiss</Button>
            <Button
              kind="primary"
              width="max-content"
              onClick={() =>
                invites.length === 1 ? acceptInvite(invite) : openPendingView()
              }
            >
              {invites.length === 1 ? "Accept" : "View Invitations"}
            </Button>
          </Flex>
        </Flex>
      </Banner>
      {invitationModalData && (
        <InvitationModal
          invitationModalData={invitationModalData}
          setInvitationModalData={setInvitationModalData}
        />
      )}
    </Panel>
  );
};

export default PendingInvitesBanner;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */

import React from "react";
import {
  Flex,
  Box,
  theme,
  Modal,
  Text,
  Button,
  Link,
} from "@xometry/xometry_loft";

export type InviteModalData = {
  inviterFirstName: string;
  inviterLastName: string;
  teamName: string;
  openStatus: boolean;
  url: string;
  isStaffActingOnBehalfOfUser: boolean;
  buttonDecision: string;
  userEmail: string;
  orgId: string;
  userName: string;
};

export interface Props {
  invitationModalData: InviteModalData;
  setInvitationModalData: (arg0: any) => void;
}

const InvitationModal = ({
  invitationModalData,
  setInvitationModalData,
}: Props) => {
  const handleModalClose = () => {
    setInvitationModalData({
      ...invitationModalData,
      openStatus: false,
    });
    const modal = document.getElementById("invitationModal");
    if (modal) {
      modal.dispatchEvent(new Event("close"));
    }
  };

  return (
    <Modal
      id="invitationModal"
      isOpen={invitationModalData.openStatus}
      breakpointMaxWidth="634px"
      showClose
      onClose={() =>
        setInvitationModalData({
          ...invitationModalData,
          openStatus: false,
        })
      }
      title={
        invitationModalData.isStaffActingOnBehalfOfUser ? (
          <Text kind="panel-title" display="block">
            Unable to {invitationModalData.buttonDecision} Team Invitation
          </Text>
        ) : (
          <Text kind="panel-title" display="block">
            {invitationModalData.buttonDecision} invitation to join{" "}
            {invitationModalData.teamName}?
          </Text>
        )
      }
    >
      {!invitationModalData.isStaffActingOnBehalfOfUser && (
        <>
          <Box marginY={theme.space[4]}>
            {invitationModalData.buttonDecision === "Accept" && (
              <Text>
                Accepting the invitation from{" "}
                {invitationModalData.inviterFirstName}{" "}
                {invitationModalData.inviterLastName} will give you access to{" "}
                {invitationModalData.teamName}
                ’s dashboard, and allow you to share your quotes and orders with
                the team.
              </Text>
            )}
            {invitationModalData.buttonDecision === "Decline" && (
              <Text>
                Declining the invitation from{" "}
                {invitationModalData.inviterFirstName}{" "}
                {invitationModalData.inviterLastName} to join{" "}
                {invitationModalData.teamName} will prevent you from sharing
                quotes and orders to this team’s dashboard.
              </Text>
            )}
          </Box>
          <Flex justifyContent="flex-end">
            <Button
              kind={theme.kind.primaryOutline}
              marginRight={theme.space[2]}
              onClick={() => handleModalClose()}
            >
              Cancel
            </Button>
            <Link href={invitationModalData.url}>
              <Button kind={theme.kind.primary}>
                {invitationModalData.buttonDecision}
                {" Invitation"}
              </Button>
            </Link>
          </Flex>
        </>
      )}
    </Modal>
  );
};

export default InvitationModal;
